.revised-time-status {
    padding: 0.4rem 0.8rem;
    border-radius: var(--border-radius-s);
    background: var(--color-meteor-95-alt);
    margin-bottom: 0.5rem;

    &__time-info {
        display: flex;
        padding: 0.2rem 0;
        justify-content: space-between;
        align-items: center;
    }

    &__time-info-label {
        color: var(--color-meteor-40);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-medium);
        line-height: var(--text-size-m);
        letter-spacing: var(--letter-spacing-m);
    }
}

.info-value {
    letter-spacing: var(--letter-spacing-l);
}
