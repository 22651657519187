.live-dispatch-popup-header {
    padding: 0 1.2rem 0.8rem;
    border-bottom: 0.1rem solid var(--color-galaxy-90);

    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .live-dispatch-popup-header__wrapper {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;

        border: none;
        background-color: var(--color-transparent);
        cursor: pointer;
    }

    .live-dispatch-popup-header__title {
        line-height: 2rem;

        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        color: var(--color-meteor);
    }
}
