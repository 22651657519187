.currency {
    height: 4rem;
    min-width: 5.7rem;
    place-items: center;
    border-radius: 0 0.4rem 0.4rem 0;
    background: var(--color-meteor-95-alt);
    border: 0.1rem solid var(--color-galaxy-90);
    border-left-width: 0;
    font-size: var(--text-size-s);
}

.invoice-number input {
    border-radius: 0.6rem 0 0 0.6rem;
    border-right: hidden;
}

.amount-due input {
    border-radius: unset;

    &:focus {
        border-color: var(--color-ocean);
    }
}

.invoice-number input,
.amount-due input {
    border: 0.1rem solid var(--color-galaxy-90);
    height: 4rem;

    &:disabled {
        background-color: var(--color-white);
        color: var(--color-black);
        cursor: default;
    }
}
