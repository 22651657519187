.addTaskModal {
    width: 84rem;
    max-height: 100vh;
}

.addTaskModal > .modal-content-wrapper {
    max-height: 100vh;
}

.addTaskModal_tabs {
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.addTaskModal .react-tabs__tab-list {
    display: flex;
    justify-content: space-around;
}

.addTaskModal_header_number {
    width: 2.2rem;
    height: 2.2rem;
    border: 0.1rem solid var(--color-galaxy-800);
    border-radius: 1.1rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.react-tabs__tab:hover .addTaskModal_header_number {
    border-color: 0.1rem solid var(--color-ocean);
}

.addTaskModal_header_tab {
    column-gap: 0.8rem;
}

.addTaskModal_footer {
    height: 9.4rem;
    padding: 2.4rem 1.6rem;
    border-top: 0.1rem solid var(--color-galaxy-200);
}

.addTaskModal_content {
    font-size: var(--text-size-s);
    padding: 3.2rem;

    height: 100%;
    min-height: 0;
    overflow: auto;
}

.addTaskModal label,
.addTaskModal_label {
    font-weight: 500;
    font-size: var(--text-size-s);
    font-family: 'Roboto';
}

.addTaskModal input {
    height: 3.2rem;
    font-size: var(--text-size-s);
    font-weight: 400;
}

.addTaskModal_divider {
    height: 0;
    border-top: 0.1rem solid var(--color-galaxy-200);
    margin-top: 2.8rem;
    margin-bottom: 2.8rem;
}
