.external-task-type-main-content {
    &__button-text {
        background-color: transparent;
        border: 0.1rem solid transparent;
        padding: 0.6rem 0.8rem;

        text-align: left;

        position: relative;
        top: -0.7rem;

        width: 100%;

        &:hover {
            background-color: var(--color-galaxy-50);
            border: 0.1rem solid var(--color-galaxy-300);
            border-radius: var(--border-radius-l);

            cursor: pointer;
        }
    }
}
