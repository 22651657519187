.revised-time-modal-content {
    font-size: var(--text-size-m);
    color: var(--color-galaxy-800);
    padding: 2.4rem;

    &__arrival-time,
    &__completed-time {
        gap: 2rem;
        margin-top: 0.8rem;
    }

    &__footer {
        margin-top: 10rem;
    }
}
