//css variables do not work with the rgba() function
$--color-meteor: #3282FF;

.taskactionbutton {
  border-radius: var(--border-radius-l);
  padding: 0.8rem 1rem;
  margin: 0 0.7rem;
  cursor: pointer;
  gap: 0.8rem;

  &:hover {
    background-color: rgba($--color-meteor, 0.4);
  }
}

.taskactionbutton-icon {
  margin-right: 1rem;
}

.taskactionbutton-chevron {
  margin-left: 0.4rem;
}

.taskactionbutton-menuitems {
  top: 5rem;
}
