.upload-zone-modal {
    &__wrapper {
        padding: 2rem;
        flex: 1 1;
    }
    &__downloadlink {
        font-size: var(--text-size-xs);
    }
}

.uploader-section {
    flex-grow: 3;
    padding: 2rem;
    margin-bottom: 2rem;
    background: var(--color-galaxy-50);
    gap: 4rem;
    width: 90%;
    height: 30rem;
    border: 0.2rem dashed var(--color-galaxy-200);
    border-radius: var(--border-radius-m);
}

.supported-text {
    gap: 1rem;
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
}

.uploader-text {
    color: var(--color-galaxy-700);
    font-size: var(--text-size-m);

    label {
        cursor: pointer;
    }
}

