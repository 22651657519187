.stop-marker-labels-tooltip {
    padding: 0.8rem 1.2rem;
    gap: 0.4rem;
    border-radius: var(--border-radius-m);
    background: var(--color-galaxy-800-alt);
    box-shadow: 0 0.2rem 0.6rem 0 var(--color-box-shadow);

    &__label {
        color: var(--color-galaxy-100);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
    }
}
