.planmapoverlay {
    column-gap: 1.6rem;
    row-gap: 1.6rem;
    z-index: var(--z-index-map-controls);

    &__live-dispatch-popup {
        top: 1.5rem;
        right: -12.4rem;
    }
}

.planmapoverlay_mapcontrols {
    bottom: 2rem;
    right: 2.4rem;
}

.planmapoverlay_livedispatch {
    left: 1.8rem;
    top: 1.2rem;
}

.planmapoverlay_stopcontrols {
    right: 2.4rem;
    top: 1.2rem;
}

.planmapoverlay_dispatch-management-popup {
    top: 12.4rem;
    right: 2.4rem;
    z-index: var(--z-index-drawers);
}
