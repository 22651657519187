.trip-list-item {
    line-height: 1.6rem;
    font-size: var(--text-size-xs);
    flex: 0 0 100%;

    &:last-child {
        margin-bottom: 0;
    }

    & .trip-list-item__summary {
        margin: 0;
        flex: 0 0 100%;
        justify-content: stretch;
        align-items: stretch;
    }

    & .routecard-metric {
        flex: 1;
        white-space: nowrap;
        font-size: var(--text-size-xs);
        color: var(--color-galaxy-800);
        min-width: fit-content;

        &:not(:last-child)::after {
            content: '\B7';
            font-weight: bold;
            font-size: var(--text-size-m);
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}
