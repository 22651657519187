.add-revise-time-button {
    margin-left: 3rem;

    & &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 2.4rem;
        width: 100%;
        background: var(--color-meteor);
        border-color: var(--color-meteor);
    }

    &__button-text {
        font-size: var(--text-size-xs);
        line-height: var(--text-size-m);
        letter-spacing: var(--letter-spacing-m);
    }
}
