.table-footer-element {
    border-top: 0.1rem solid var(--color-galaxy-80);
    display: flex;
    column-gap: 1.8rem;
    height: 4.8rem;
    padding: 0.4rem 2.4rem;

    &__content {
        width: 100%;
        padding: 1rem 0;
        white-space: nowrap;
        font-size: var(--text-size-s);
        line-height: 2rem;
    }

    &__actions {
        padding: 0.4rem 0;
        display: flex;
        column-gap: 1.2rem;
    }

    &__action {
        &:disabled:hover svg {
            fill: var(--color-galaxy-400);
        }
        &:hover svg {
            fill: var(--color-meteor);
        }
    }

    &__status {
        padding: 1rem 0;
        white-space: nowrap;
        font-size: var(--text-size-s);
        line-height: 2rem;
    }
}
