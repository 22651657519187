.stop-marker-name-and-labels {
    padding: 0.2rem 0.2rem 0.2rem 0.8rem;
    gap: 0.4rem;
    border-radius: var(--border-radius-l);
    background: var(--color-galaxy-100);
    width: max-content;

    &__name {
        color: var(--color-galaxy-800);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        max-width: 13.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    &__labels {
        padding: 0 0.8rem;
        border-radius: var(--border-radius-xxl);
        border: 0.1rem solid var(--color-galaxy-300-alt);
        background: var(--color-galaxy-100);
        gap: 0.2rem;

        &:hover {
            border: 0.1rem solid var(--color-sky);
            background: var(--color-meteor-90);
        }
    }

    &__label {
        color: var(--color-galaxy-800-alt);
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        text-transform: capitalize;
    }
}
