.live-dispatch-view-columns-dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.6rem;
    padding: 0.8rem 1.6rem;
    transition: background-color 0.3s;
    &:hover {
        background-color: var(--color-galaxy-50);
    }
    &.visible {
        &:hover .live-dispatch-visible-column__hide-button {
            display: inline-block;
        }
    }
    &.hidden {
        .live-dispatch-hidden-column__column-name {
            color: var(--color-meteor-70);
        }
        .live-dispatch-hidden-column__visibility-off-icon {
            display: none;
        }
        &:hover {
            .live-dispatch-hidden-column__visibility-off-icon,
            .live-dispatch-hidden-column__view-button {
                display: inline-block;
            }
        }
    }
    .live-dispatch-hidden-column__column-name {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        .live-dispatch-hidden-column__visibility-off-icon {
            margin-right: 0.5rem;
            font-weight: bold;
        }
    }
    .live-dispatch-hidden-column__item-label {
        color: var(--color-meteor-70-alt);
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-s);
    }
    .live-dispatch-visible-column__item-label {
        color: var(--color-galaxy-800);
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-s);
    }
    .live-dispatch-hidden-column__view-button-label,
    .live-dispatch-hidden-column__hide-button-label {
        color: var(--color-meteor);
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-l);
    }
    .live-dispatch-visible-column__hide-button,
    .live-dispatch-hidden-column__view-button {
        display: none;
        background: none;
        border: none;
        cursor: pointer;
    }
}
