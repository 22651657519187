@import 'src/ui/mixins/scrollbar';
@import 'src/components/MapPageDrawers/LiveDispatchDrawer/variables';

.live-dispatch-table__wrapper {
    overflow-x: auto;
    height: 100%;
    @include thin-scrollbar($scrollbar-width: 0.8rem);
}

.live-dispatch-table__table {
    border-collapse: collapse;
    flex-grow: 0;

    tr,
    th,
    td {
        border-width: 0.1rem;
        border-style: solid;
        height: auto;
    }

    td {
        padding: $live-dispatch-table-data-padding;
        background-color: var(--color-comet);
        border-color: var(--color-galaxy-90);
    }

    &--stickied {
        top: 0;
        position: sticky;
        margin-bottom: -0.1rem;

        td {
            border-color: var(--color-meteor-70);
            background-color: var(--color-meteor-90);
        }
    }
}

.live-dispatch-table__table-header,
.live-dispatch-table__table-body {
    text-transform: none;
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
    letter-spacing: var(--letter-spacing-m);
}

.live-dispatch-table__table-header {
    background-color: var(--color-saturn-100);
    color: var(--color-galaxy-500);
}

.live-dispatch-table__table-body {
    color: var(--color-galaxy-800);
}
