.routedriversearch-input {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-l);
    font-size: var(--text-size-m);
    padding: 1.2rem 1.6rem;

    &:focus {
        outline: none;
        border-color: var(--color-ocean);
        background-color: var(--color-neptune-100);
    }

    &::placeholder {
        color: var(--color-galaxy-300);
    }
}

.routedriversearch-button {
    position: relative;
    left: 100%;
    margin-left: -4rem;
    bottom: 3.8rem;
}

.routedriversearch-dropdown {
    position: relative;
    right: 0;
    margin-top: -3rem;
    border-radius: var(--border-radius-l);
    box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
    background-color: var(--color-comet);
    max-height: 20rem;
    overflow: auto;
}

.routedriversearch-dropdown-item {
    padding: 1rem 2rem;
    cursor: pointer;
    color: var(--color-galaxy-800);

    &:hover {
        background-color: var(--color-galaxy-50);
    }

    .route-driver-name {
        white-space: nowrap;
        margin-right: 0.8rem;
    }

    .driver-tags {
        .out-of-service:not(:last-child) {
            margin-right: 0.8rem;
        }
    }
}

.routedriversearch-item-label {
    border: 0.1rem solid var(--color-ocean);
    border-radius: var(--border-radius-m);
    color: var(--color-meteor);
    background-color: var(--color-neptune-100);
    padding: 0.2rem 0.4rem;
}
