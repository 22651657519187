.live-dispatch-select-driver-card {
    padding: 1.2rem;
    margin-top: 0.4rem;
    border-radius: var(--border-radius-l);
    border: 0.1rem dashed var(--color-galaxy-60);
    text-align: center;
    width: 100%;

    &__button {
        background-color: var(--color-white);
        color: var(--color-meteor);
        display: flex;
        border: none;
        border-radius: var(--border-radius-m);
        padding: 0.4rem;
        margin-top: 0.4rem;
        gap: 0.2rem;

        cursor: pointer;

        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-xs);

        align-items: center;

        width: 8.1rem;
        margin: auto;

        &:hover {
            background-color: var(--color-meteor-90);
        }
    }

    .live-dispatch-select-driver-card__title,
    .live-dispatch-select-driver-card__subtitle {
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        display: block;

        font-size: var(--text-size-xs);
        color: var(--color-galaxy-800);
    }

    .live-dispatch-select-driver-card__title {
        font-weight: var(--font-weight-bold);
    }

    .live-dispatch-select-driver-card__subtitle {
        font-weight: var(--font-weight-normal);
    }
}
