@import 'src/ui/mixins/color-modifier';

.task-history-status {
    display: grid;
    grid-template-columns: 2rem 1fr;
    column-gap: 1rem;

    &__circle {
        justify-self: center;
        align-self: center;
    }

    &__name--default {
        font-weight: 400;
        color: var(--color-galaxy-500);
    }

    &__line {
        justify-self: center;
        align-self: center;
        border-left: 0.1rem solid;
        height: 100%;
        min-height: 1.6rem;
        @include color-modifier('color');

        &--hidden {
            visibility: hidden;
        }
    }

    &__notification {
        margin: 0.8rem 0 0.4rem 0;
    }

    &__notification-text {
        color: var(--color-galaxy-600);
    }
}
