.dropdown-flex {
    display: flex;
    align-items: center;
    color: var(--color-comet);
    padding: 1.5rem;
    cursor: pointer;
}

.dropdown-flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-comet);
    padding: 1.5rem;
    cursor: pointer;
}

.dropdown_outlined {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-l);
    background-color: transparent;
    padding: 0.8rem 1rem 0.8rem 1.4rem;

    &:hover {
        background-color: var(--color-neptune-100);
        border-color: var(--color-ocean);
    }
}

.dropdown-title-text {
    color: var(--color-galaxy-800);
}

.dropdown--placeholder {
    color: var(--color-galaxy-400);
}

.dropdown-flex-item {
    display: flex;
    padding: 0 1rem;
}

.dropdown-hide-items {
    display: none;
}

.dropdown-show-items {
    /** Space between the top of the dropdown and the bottom of its anchor element */
    --dropdown-top-spacing: 0.4rem;

    display: block;
    position: absolute;
    transform: translate(0, var(--dropdown-top-spacing));
    min-width: 20rem;
    white-space: nowrap;
    background-color: var(--color-comet);
    box-shadow: 0 0.8rem 1.6rem 0 var(--color-box-shadow);
    border-radius: 0.8rem;
    z-index: var(--z-index-sticky-header);

    &.down {
        top: 100%;
        right: 0.1rem;
    }

    &.down-right {
        top: 100%;
        left: 0.1rem;
    }

    &.left {
        right: 5.6rem;
        top: -1.6rem;
    }
}

.dropdown-item {
    font-size: var(--text-size-s);
    color: var(--color-black);
}

.dropdown-item:hover {
    background-color: var(--color-galaxy-100);
    cursor: pointer;
}

.dropdown-item:first-child:hover {
    border-radius: 0.8rem 0.8rem 0 0;
}

.dropdown-item:last-child:hover {
    border-radius: 0 0 0.8rem 0.8rem;
}

.dropdown-item:only-child:hover {
    border-radius: 0.8rem;
}

.dropdown-item.disabled {
    cursor: not-allowed;
    color: var(--color-galaxy-400);
    pointer-events: none;

    .checkbox-box {
        background-color: var(--color-galaxy-200);
        .icon_checkmark {
            display: none;
        }
    }
}

.dropdown-icon {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: var(--color-black);
    margin: 0 0.8rem;
}

.dropdown-title-text {
    align-self: center;
    min-width: 7rem;
}

.dropdown-circle-icon_white {
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--color-black);
    border-radius: 50%;
    background-color: var(--color-comet);
    color: var(--color-ocean);
    border-color: var(--color-comet);
    font-size: 1.6rem;
}

.dropdown-circle-icon_black {
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--color-black);
    border-radius: 50%;
    color: var(--color-black);
    margin: 0;
}

.dropdown-selection {
    display: flex;
    font-size: var(--text-size-s);
    color: var(--color-galaxy-800);
    column-gap: 0.8rem;
}
