.invoice-and-payment {
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: var(--border-radius-l);
    padding: 1rem;

    &:hover {
        background-color: var(--color-neptune-100);
    }
}

.invoice-and-payment__detail {
    width: 20rem;
}
