.live-dispatch-table-selected-toolbar {
    padding: 1rem 1.2rem;
    background-color: var(--color-meteor-50);
    color: var(--color-comet);
    font-size: 1.4rem;

    &__actions {
        display: flex;
        gap: 1.6rem;
    }
}

.live-dispatch-table-selected-toolbar__status-text {
    color: var(--color-comet);
}

.live-dispatch-table-toolbar__buttons-container {
    border-radius: var(--border-radius-l);
    .taskactionbutton {
        gap: 0.8rem;
        padding: 0.8rem;
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        background-color: var(--color-meteor-50);
        color: var(--color-comet);
        &:hover {
            background-color: var(--color-meteor-40);
        }
    }
}
