.timeinput {
    margin: 0;
    color: var(--color-galaxy-800);
    border: solid 0.1rem var(--color-galaxy-300);
    border-radius: 0.8rem;
    font-size: 1.6rem;

    &:focus {
        outline: none;
        border-color: var(--color-ocean);
        background-color: var(--color-neptune-100);
    }

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }

    &:disabled {
        color: var(--color-galaxy-400);
        background-color: transparent;
    }

    &.with_placeholder:before {
        content: attr(data-placeholder);
        color: var(--color-galaxy-400);
        position: absolute;
        background: var(--color-comet);
        width: 10rem;
    }

    &.with_placeholder:focus:before {
        width: 0;
        content: '';
    }
}

.timeinput_table {
    padding: 0.6rem 0.8rem 0.6rem 1.6rem;
    width: 16rem;

    &::-webkit-calendar-picker-indicator {
        opacity: 0;
        z-index: var(--z-index-action);
    }
}

.timeinput_naked {
    border: 0;

    &::-webkit-calendar-picker-indicator {
        display: none;
        z-index: var(--z-index-action);
    }
}

.timeinput_naked {
    border: 0;

    &::-webkit-calendar-picker-indicator {
        display: none;
        z-index: var(--z-index-action);
    }
}

.timeinput_form {
    width: 100%;
    padding: 1.2rem 1.6rem;
}

.timeinput_live_dispatch {
    width: 11rem;
    height: 4rem;
    padding: 0.6rem 0.8em;
    font-size: var(--text-size-xs);
}

.timeinput_error {
    border-color: var(--color-mars-300);

    &:focus {
        border-color: var(--color-mars-300);
        background-color: var(--color-mars-100);
    }
}

.timeinput-icon {
    position: relative;
    left: -2.9rem;
}
