.live-dispatch-popup-content-header {
    padding: 1.2rem 0 0;

    &__title {
        display: block;
        line-height: 2.8rem;
        font-size: var(--text-size-xm);
        font-weight: var(--font-weight-normal);
        color: var(--color-galaxy-800);
    }

    &__subtitle {
        line-height: 2rem;
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-800);
    }
}
