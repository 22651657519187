.live-dispatch-popup {
    .popup-submit-button {
        width: 100%;
        height: 4.4rem;
        margin-top: 1.6rem;
        border: none;
        background-color: var(--color-meteor);
        border-radius: var(--border-radius-l);
        &:hover,
        &:active {
            cursor: pointer;
            background-color: var(--color-neptune);
        }
        &:disabled {
            opacity: 0.2;
            &:hover {
                cursor: default;
                background-color: var(--color-meteor);
            }
        }
        &__wrapper {
            column-gap: 0.8rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        &__label {
            line-height: 2rem;
            color: var(--color-comet);
            font-size: var(--text-size-s);
            font-weight: var(--font-weight-medium);
        }
    }
}
