.live-dispatch-task-card {
    column-gap: 0.8rem;
    padding: 0.8rem;

    display: flex;
    flex-direction: row;

    border-radius: var(--border-radius-l);
    background: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 rgba(24, 41, 65, 0.15);

    .live-dispatch-task-card__summary {
        display: flex;
        flex-direction: column;

        gap: 0.4rem;
    }

    .live-dispatch-task-card__marker {
        background-color: var(--color-galaxy-500);
        border-radius: var(--border-radius-round);
        height: 2.4rem;
        width: 2.4rem;
    }

    .live-dispatch-task-card__text {
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        display: block;

        font-size: var(--text-size-xs);
        color: var(--color-galaxy-800);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 20rem;
    }

    .live-dispatch-task-card__text:first-child {
        font-weight: var(--font-weight-medium);
    }

    .live-dispatch-task-card__text:last-child {
        font-weight: var(--font-weight-normal);
    }

    .live-dispatch-task-card__service-time-wrapper {
        display: flex;
        column-gap: 0.8rem;
    }
}
