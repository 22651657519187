.live-dispatch-table-data {
    line-height: 1.6rem;
    font-size: var(--text-size-xs);

    &--truncate {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & .highlight__highlight {
        color: var(--color-meteor);
    }
}
