.inventoryitem {
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: var(--border-radius-l);
    padding: 1rem;
}
.inventoryitem-edit_enabled:hover {
    background-color: var(--color-neptune-100);
}

.inventoryitem-detail {
    width: 20rem;
}
