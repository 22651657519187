.out-of-service {
    align-self: baseline;
    padding: 0 0.8rem;
    background-color: var(--color-saturn-99);
    border: 0.1rem solid var(--color-saturn-60);
    border-radius: var(--border-radius-xxl);
    font-weight: 500;
    font-size: var(--text-size-xs);
    line-height: 1.2rem;

    .icon {
        height: auto;
        padding-right: 0.5rem;
    }
}
