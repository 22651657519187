@import 'src/ui/mixins/scrollbar';

.live-dispatch-popup-body {
    padding: 0 1.6rem;

    .live-dispatch-tasks-container {
        row-gap: 0.8rem;
        margin-top: 0.8rem;
        max-height: 21.8rem;
        overflow: auto;

        display: flex;
        flex-direction: column;

        @include thin-scrollbar;

        & .live-dispatch-popup-card {
            &:not(:last-child) {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 2.8rem;
                    height: 2.8rem;
                    border: 0.2rem solid var(--color-galaxy-90);
                    background: var(--color-galaxy-100);
                    background-image: url('~/ui/assets/icons/insert-link.svg');
                    background-repeat: no-repeat;
                    background-position: center;

                    border-radius: var(--border-radius-round);
                    top: calc(100% - 0.9rem);
                    left: calc(50% - 1.1rem);
                    z-index: var(--z-index-plan-map-zone-edit-button);
                }
            }
        }
    }
}

.pair-equipment-control {
    &__note {
        color: var(--color-galaxy-700);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
    }
    &__not-highlight-text {
        font-weight: var(--font-weight-bold);
    }
}
