.live-dispatch-drawer {
    width: 110rem;
    padding: 1rem;
    background-color: var(--color-comet);
    align-self: flex-start;
    border-radius: var(--border-radius-l);
    .live-dispatch-tabs-content {
        border: 0.1rem solid var(--color-galaxy-80);
        border-radius: var(--border-radius-l);
        margin-top: 0.8rem;
    }
    .navigationtabsgroup__button {
        text-align: left;
        padding: 1.6rem;
        display: flex;
        gap: 0.4rem;
        color: var(--color-galaxy-800);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        &:first-child {
            border-top-left-radius: var(--border-radius-l);
        }
        &:last-child {
            border-top-right-radius: var(--border-radius-l);
        }
        &[data-selected] {
            font-weight: var(--font-weight-medium);
            padding-top: 1.3rem;
        }
    }
}
.live-dispatch-drawer--expanded {
    .live-dispatch-table,
    .equipment-table {
        height: 58.4vh;
    }
}
.live-dispatch-drawer--collapsed {
    .live-dispatch-table,
    .equipment-table {
        height: 34.4vh;
    }
}

.livedispatchdrawer-filters-container {
    display: flex;
    justify-content: flex-end;
}

.live-stop-drawer__header {
    padding: 1.6rem 2.4rem;
}
