@import 'src/components/MapPageDrawers/LiveDispatchDrawer/variables';

.live-dispatch-table__table-header-cell {
    border-color: var(--color-galaxy-90);
    padding: $live-dispatch-table-header-padding;
    min-width: 3.7rem;

    &--has-selected {
        border-bottom-color: var(--color-meteor-70);
    }
}

.live-dispatch-table-header__rowTaskType {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowTaskType
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowExtTaskType {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowExtTaskType
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowTaskPriority {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowTaskPriority
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowDate {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDate
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowTimeWindow {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowTimeWindow
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowPickupStartTime {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupStartTime
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowPickupEndTime {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupEndTime
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowDeliveryStartTime {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryStartTime
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowDeliveryEndTime {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryEndTime
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowName {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowName
    );
}

.live-dispatch-table-header__rowLabels {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowLabels
    );
}

.live-dispatch-table-header__rowDate {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDate
    );
}

.live-dispatch-table-header__rowTimeWindow {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowTimeWindow
    );
}

.live-dispatch-table-header__rowPickupStartTime {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupStartTime
    );
}

.live-dispatch-table-header__rowPickupEndTime {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupEndTime
    );
}

.live-dispatch-table-header__rowDeliveryStartTime {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryStartTime
    );
}

.live-dispatch-table-header__rowDeliveryEndTime {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryEndTime
    );
}

.live-dispatch-table-header__rowPickupCustomerName {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupCustomerName
    );
}

.live-dispatch-table-header__rowPickupCustomerAddress {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupCustomerAddress
    );
}

.live-dispatch-table-header__rowPickupCustomerCity {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowPickupCustomerCity
    );
}

.live-dispatch-table-header__rowDeliveryCustomerName {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerName
    );
}

.live-dispatch-table-header__rowDeliveryCustomerAddress {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerAddress
    );
}

.live-dispatch-table-header__rowDeliveryCustomerCity {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerCity
    );
}

.live-dispatch-table-header__rowExtLinks {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowExtLinks
    );
}

.live-dispatch-table-header__rowNotes {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowNotes
    );
}

.live-dispatch-table-header__rowTaskPriority {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowTaskPriority
    );
}

.live-dispatch-table-header__rowEquipmentId {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowEquipmentId
    );
}

.live-dispatch-table-header__rowEquipmentPayerOfFreight {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowEquipmentPayerOfFreight
    );
}

.live-dispatch-table-header__rowEquipmentReservation {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowEquipmentReservation
    );
}

.live-dispatch-table-header__rowEquipmentType {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowEquipmentType
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}

.live-dispatch-table-header__rowEquipmentDropStay {
    width: live-dispatch-table-header-width(
        $live-dispatch-table-col-width--rowEquipmentDropStay
    );
}

.live-dispatch-table-header {
    .dropdown-show-items {
        padding: 0 1.6rem;
        min-width: 28rem;

        .filter-table-header__filter-header {
            padding: 1.2rem 0;
            span {
                font-size: var(--text-size-s);
                font-weight: var(--font-weight-normal);
                color: var(--color-live-dispatch-header);
            }
        }

        .filter-table-header__filter-list-container {
            max-height: 35rem;
            overflow-y: auto;
        }

        .filter-table-header__time-select {
            padding: 0.4rem 0;
            span {
                padding: 1.2rem 0.2rem;
                color: var(--color-live-dispatch-header);
                font-size: var(--text-size-vs);
                font-weight: var(--font-weight-normal);
            }
        }

        .filter-table-header__timeWindow {
            position: relative;
            display: flex;
            justify-content: space-between;
            column-gap: 0.4rem;
        }

        .dropdown-item {
            .checkbox {
                font-size: var(--text-size-s);
                font-weight: var(--font-weight-normal);
                color: var(--color-galaxy-500-alt);
                text-transform: capitalize;
            }
        }

        .filter-table-header__filter-footer {
            padding: 0.8rem 0;
        }
    }

    .filter-table-header__filter-clear {
        color: var(--color-meteor);

        &:hover {
            color: var(--color-neptune);
        }
    }

    .filter-table-header__filter-apply {
        font-weight: var(--font-weight-normal);
    }
    .sortable-header {
        display: flex;
        align-items: center;
    }
}
