.footer-with-delete-save-and-cancel-buttons {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    gap: 2rem;

    & button {
        padding: 1.2rem 3.2rem;
    }
}

.save-and-cancel_button-group {
    gap: 2rem;
}
