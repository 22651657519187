.live-dispatch-table-selected-toolbar {
    padding: 1rem 1.2rem;
    background-color: var(--color-meteor-50);
    color: var(--color-comet);
    font-size: 1.4rem;
}

.live-dispatch-table-selected-toolbar__status-text {
    color: var(--color-comet);
}
