.here-map-popup-overlay {
    position: absolute;
    z-index: var(--z-index-map-controls-underlay);
}

.here-map-wrapper {
    position: relative;
    height: 100%;
}

.here-map-container {
    width: 100%;
    height: 100%;
}
