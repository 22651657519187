@import 'src/components/MapPageDrawers/LiveDispatchDrawer/variables';

.equipment-table__table-header-cell {
    border-color: var(--color-galaxy-90);
    padding: $live-dispatch-table-header-padding;
    min-width: 3.7rem;

    &--has-selected {
        border-bottom-color: var(--color-meteor-70);
    }
}

.equipment-table-header__id {
    width: live-dispatch-table-header-width($equipment-table-col-width--id);
}

.equipment-table-header__name {
    width: live-dispatch-table-header-width($equipment-table-col-width--name);
}

.equipment-table-header__type {
    width: live-dispatch-table-header-width($equipment-table-col-width--type);
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown {
        margin-right: 0.5rem;
    }
}

.equipment-table-header__coordinates {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--coordinates
    );
}

.equipment-table-header__status {
    width: live-dispatch-table-header-width($equipment-table-col-width--status);
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown {
        margin-right: 0.5rem;
    }
}

.equipment-table-header__state {
    width: live-dispatch-table-header-width($equipment-table-col-width--state);
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown {
        margin-right: 0.5rem;
    }
}

.equipment-table-header__attached {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--attached
    );
}

.equipment-table-header__updatedAt {
    display: flex;
    align-items: center;
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--updatedAt
    );
    .icon_filterMenu {
        margin-right: 1rem;
    }
}
.equipment-table-header__status {
    width: live-dispatch-table-header-width($equipment-table-col-width--status);
}

.equipment-table-header__customerId {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerId
    );
}

.equipment-table-header__customerAddressLine1 {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerAddressLine1
    );
}

.equipment-table-header__customerCity {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerCity
    );
}

.equipment-table-header__customerState {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerState
    );
}

.equipment-table-header__customerPostalCode {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerPostalCode
    );
}

.equipment-table-header__customerName {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--customerName
    );
}

.equipment-table-header__dispatchZone {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--dispatchZone
    );
}

.equipment-table-header__area {
    width: live-dispatch-table-header-width($equipment-table-col-width--area);
}

.equipment-table-header__chassisId {
    width: live-dispatch-table-header-width(
        $equipment-table-col-width--chassisId
    );
}
