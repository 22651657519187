.live-dispatch-task-time-content {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;

    .live-dispatch-task-time-content__text {
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);

        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        color: var(--color-galaxy-800);
    }
}
