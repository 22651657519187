.task-labels-detail-content {
    &__default-view {
        border: 0.1rem solid transparent;
        border-radius: var(--border-radius-l);
        padding: 0.6rem 0.8rem;
        bottom: 0.7rem;
        cursor: default;
        gap: 0.8rem;

        &--editable:hover {
            border-color: var(--color-galaxy-300);
            background-color: var(--color-galaxy-50);
            cursor: pointer;
        }

        .label {
            border: 0.1rem solid var(--color-galaxy-200);
            color: var(--color-galaxy-800);
            font-size: var(--text-size-xs);
            font-weight: var(--font-weight-normal);
            letter-spacing: var(--letter-spacing-m);
            line-height: 1.6rem;
        }
    }
}
