.live-dispatch-view-columns-dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);
    margin-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background-color: var(--color-comet);
    z-index: 1;
    height: 4.4rem;
    &__label {
        color: var(--color-galaxy-800);
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-xxxs);
    }
}
