.notification-status {
    display: flex;
    justify-content: space-between;
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: var(--border-radius-l);
    padding: 1rem;
    font-size: var(--text-size-xs);
    margin-bottom: 1rem;
    width: 100%;
    color: var(--color-galaxy-800);

    &:disabled {
        &,
        &:hover {
            background-color: var(--color-comet);
            border-color: var(--color-galaxy-200);
            cursor: default;
        }
    }
}

.notification-status:hover {
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
    cursor: pointer;
}

.notification-status__title {
    font-size: var(--text-size-s);
}

.notification-status--active {
    color: var(--color-meteor);
    display: flex;
    border: 0.1rem solid var(--color-ocean);
    background-color: var(--color-neptune-100);
    border-radius: var(--border-radius-m);
    padding: 0.2rem 0.4rem;
}

.notification-status--inactive {
    color: var(--color-galaxy-500);
    border-color: var(--color-galaxy-200);
}

.notification-status--background-inactive {
    background-color: var(--color-galaxy-100);
}
