.live-dispatch-popup-body {
    padding: 1.2rem 1.6rem 0;

    .live-dispatch-popup-body__title {
        display: block;
        line-height: 2.8rem;
        font-size: var(--text-size-xm);
        font-weight: var(--font-weight-normal);
        color: var(--color-galaxy-800);
    }

    .live-dispatch-popup-body__subtitle {
        line-height: 2rem;
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-800);
    }

    .live-dispatch-tasks-container {
        & .live-dispatch-task-card {
            &:not(:last-child) {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 2.8rem;
                    height: 2.8rem;
                    border: 0.2rem solid var(--color-galaxy-90);
                    background: var(--color-galaxy-100);
                    background-image: url('~/ui/assets/icons/insert-link.svg');
                    background-repeat: no-repeat;
                    background-position: center;

                    border-radius: var(--border-radius-round);
                    top: calc(100% - 0.9rem);
                    left: calc(50% - 1.1rem);
                    z-index: 1;
                }
            }
        }
    }
}
