.equipment-table-search-bar {
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: var(--border-radius-l);
    width: 40rem;
    height: 3.6rem;
    column-gap: 0.8rem;

    &--focused,
    &--focused:hover {
        border-color: var(--color-meteor);
    }

    &--filled {
        border-color: var(--color-meteor);
    }
}

.equipment-table-search-bar__search {
    width: 100%;
    input {
        border: none;
        outline: none;
        padding: 0.7;
        line-height: 2rem;
        font-size: var(--text-size-s);
        border-radius: 0;
    }
}

.equipment-table-search-bar__icon {
    padding-top: 0.7rem;
    padding-left: 0.5rem;
}

.equipment-table-search-bar__button_search {
    background-color: var(--color-ocean);
    border: 0.1rem solid var(--color-ocean);
    border-radius: 0 0.7rem 0.7rem 0;
    width: 4.2rem;
    align-self: stretch;
    &:hover {
        svg {
            fill: var(--color-meteor);
        }
    }
}

.equipment-table-search-bar__button_clear {
    background-color: var(--color-comet);
    padding: 0 1.6rem;
    color: var(--color-meteor);
    font-size: 1.4rem;
    border: none;

    &:hover {
        color: var(--color-neptune-500);
    }
}
