.routeitem {
    & + & {
        margin-top: 1.4rem;
    }
}

.routeitem-main {
    flex-grow: 1;

    &:hover {
        cursor: pointer;
    }

    .routecard-top:not(:first-of-type) {
        margin-top: 0.6rem;
    }
}

.routeitem__markers {
    height: 4rem;
    gap: 0.2rem;
}

.routeitem__markers--heightened {
    height: 5rem;
}

.routeitem__visibility {
    width: 2rem;
    height: 2rem;
}

.routeitem__livedispatch {
    margin-top: 1.2rem;
    margin-left: 0.3rem;
}

.routeitem__summary {
    display: flex;
    gap: 0.4rem;
    flex-direction: row;
    justify-content: space-between;
}

.routeitem__trips {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    flex: 1;
}

.routeitem__trip-title {
    min-height: 2rem;
}
