.geofencedropdown {
    background-color: var(--color-comet);
    box-shadow: 0 0.2rem 0.6rem 0 var(--color-box-shadow);
    border-radius: var(--border-radius-l);
    padding: 0.8rem;
    min-width: 16rem;

    &:hover {
        background-color: var(--color-neptune-100);
    }
}

.geofencedropdown-title {
    margin-right: 0.8rem;
    font-size: var(--text-size-xs);
    font-weight: 500;
}

.geofencedropdown-menuitems {
    min-width: 16rem;

    &.down {
        top: 3rem;
    }

    & .dropdown-item {
        font-size: var(--text-size-xs);
        padding: 1.2rem 1.6rem;
        &:hover {
            background-color: var(--color-neptune-100);
        }

    }
}
