.sortingDropdown {
    min-width: 20rem !important;
}
.table-header {
    &__sortingIcon {
        margin-right: 0.5rem !important;
    }
    &__sortingHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        & > span {
            font-size: var(--text-size-s);
            font-weight: normal;
        }
    }
    &__sortingPartition {
        margin: 0 -20px;
        border: 0.1rem solid var(--color-galaxy-200);
    }
    &__sortingBody {
        margin: 0.8rem 0;
        .sortingMenuContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .sortingMenuTextBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &.selected,
            &.applied {
                svg {
                    fill: var(--color-galaxy-800);
                }
                button {
                    color: var(--color-galaxy-800);
                }
            }
        }
        .sortingMenuText {
            color: var(--color-galaxy-500);
            font-weight: normal;
            margin: 0.8rem 0;
            font-size: var(--text-size-s);
        }
    }
    &__sortingFooter {
        margin: 0.8rem 1rem;
    }
    &-sorting-clear {
        color: var(--color-galaxy-400);
        font-size: var(--text-size-s);
        font-weight: normal;
        &.active {
            color: var(--color-meteor);
        }
    }
}
