.invoice-and-payment-edit-modal {
    &__body {
        height: 29rem;
        padding: 2.4rem;
        overflow: scroll;
    }

    &__payment-details {
        color: var(--color-neptune-700);
    }
}
