.iterable-items {
    border: solid 0.1rem transparent;
    border-radius: var(--border-radius-l);
    padding: 0.6rem 0.8rem 0;
    position: relative;
    bottom: 0.7rem;
    cursor: default;

    display: flex;
    gap: 0.8rem;

    &__item {
        border: 0.1rem solid var(--color-galaxy-200);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        padding: 0.2rem 0.8rem;
        font-size: var(--text-size-xs);
        border-radius: var(--border-radius-m);
    }
}
