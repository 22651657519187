.live-dispatch-view-columns {
    .live-dispatch-view-columns-button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        height: 3.6rem;
        padding: 0.8rem;
        background-color: var(--color-white);
        border-radius: var(--border-radius-l);
        cursor: pointer;
        &:hover {
            background-color: var(--color-meteor-95);
        }
        &.active {
            background-color: var(--color-meteor-95);
        }
        &__label {
            letter-spacing: var(--letter-spacing-s);
        }
        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.8rem;
        }
        &.button-label {
            background-color: transparent;
            color: var(--color-galaxy-800);
            font-size: var(--text-size-s);
            font-weight: var(--font-weight-normal);
            padding: 0;
            &--selected {
                color: var(--color-meteor);
            }
        }
    }
}
