.live-dispatch-popup {
    transform: translate(-50%, 0);
    width: 28.1rem;
    padding: 1.6rem 0;
    background-color: var(--color-comet-alpha-70);
    border-radius: var(--border-radius-l);
    backdrop-filter: blur(2.4rem);
}

/*fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .live-dispatch-popup {
        background-color: rgb(248, 247, 245, 1);
    }
}
