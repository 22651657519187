.live-dispatch-view-columns {
    position: relative;

    .live-dispatch-view-columns__dropdown-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 28rem;
        background: var(--color-comet);
        border: none;
        border-radius: var(--border-radius-l);
        box-shadow: 0 0.5rem 1.5rem 0 rgba(24, 41, 65, 0.15);
        overflow: hidden;

        z-index: 2;
    }
}
