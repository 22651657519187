.labelinput-title {
    margin-bottom: 0.8rem;
}

.secondary-labelinput-title {
    margin-top: 1.6rem;
}

.label-input {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: 0.6rem;
    font-size: 1.6rem;
    padding: 1.2rem 1.6rem;
}

.label-input:focus {
    outline: none;
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
}

.label-input::placeholder {
    color: var(--color-galaxy-400);
}

.label-input:disabled {
    background-color: var(--color-galaxy-100);
    cursor: not-allowed;
}

.label-input--error {
    border-color: var(--color-mars);
}

.label-input--error:focus {
    border-color: var(--color-mars);
    background-color: var(--color-mars-100);
}

.label-input_password {
    padding-right: 11.5rem;
}

/** @TODO Unify this style with .radio-group_asterisk */
.label-input_asterisk {
    color: var(--color-ocean);
    font-size: var(--text-size-s);
    font-weight: 500;
}

.add-user-password-field {
    padding: 0;
    position: relative;
    background: none;
    border: none;
    outline: none;
    bottom: 2.82rem;
    margin-left: auto;
    margin-right: 2.6rem;
    font-size: 1.3rem;
    color: var(--color-meteor);
}

.add-user-password-field:hover {
    color: var(--color-neptune-500);
    cursor: pointer;
}

.forgot-password-field {
    padding: 0;
    position: relative;
    background: none;
    border: none;
    bottom: 3.82rem;
    margin-bottom: revert;
    left: 29rem;
    height: 0;
    width: 10rem;
    font-size: 1.3rem;
    color: var(--color-meteor);
}

.forgot-password-field:hover {
    color: var(--color-neptune-500);
    cursor: pointer;
}
