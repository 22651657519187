.live-dispatch-table-data__note-links {
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
    color: var(--color-meteor);
    text-decoration: none;
}

.live-dispatch-table-row__data-note {
    white-space: pre-line;
}

.live-dispatch-table-data__divider {
    grid-column-start: 1;
    grid-column-end: 7;
    height: 0;
    border-top: 0.1rem solid var(--color-galaxy-200);
    margin: 0.2rem 0;
}
