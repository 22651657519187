@import 'src/components/MapPageDrawers/LiveDispatchDrawer/variables';
@import 'src/components/MapPageDrawers/LiveDispatchDrawer/mixins';

.equipment-table-data__id {
    width: live-dispatch-table-data-width($equipment-table-col-width--id);
}

.equipment-table-data__name {
    width: live-dispatch-table-data-width($equipment-table-col-width--name);
}

.equipment-table-data__type {
    width: live-dispatch-table-data-width($equipment-table-col-width--name);
}

.equipment-table-data__coordinates {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--coordinates
    );
}

.equipment-table-data__status {
    width: live-dispatch-table-data-width($equipment-table-col-width--status);
}

.equipment-table-data__state {
    width: live-dispatch-table-data-width($equipment-table-col-width--state);
    @include flex-cell-data($flex-direction: row);
}

.equipment-table-data__attached {
    width: live-dispatch-table-data-width($equipment-table-col-width--attached);
    @include flex-cell-data($flex-direction: row);
}

.equipment-table-data__updatedAt {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--updatedAt
    );
}

.equipment-table-data__customerId {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerId
    );
}

.equipment-table-data__customerAddressLine1 {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerAddressLine1
    );
}

.equipment-table-data__customerCity {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerCity
    );
}

.equipment-table-data__customerState {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerState
    );
}

.equipment-table-data__customerPostalCode {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerPostalCode
    );
}

.equipment-table-data__customerName {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--customerName
    );
}

.equipment-table-data__dispatchZone {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--dispatchZone
    );
}

.equipment-table-data__area {
    width: live-dispatch-table-data-width($equipment-table-col-width--area);
}

.equipment-table-data__chassisId {
    width: live-dispatch-table-data-width(
        $equipment-table-col-width--chassisId
    );
}

.equipment-table-header {
    .dropdown-show-items {
        padding: 0 1.6rem;
        min-width: 28rem;

        .filter-table-header__filter-header {
            padding: 1.2rem 0;
            span {
                font-size: var(--text-size-s);
                font-weight: var(--font-weight-normal);
                color: var(--color-live-dispatch-header);
            }
        }

        .filter-table-header__filter-list-container {
            max-height: 35rem;
            overflow-y: auto;
        }

        .dropdown-item {
            .checkbox {
                font-size: 1.4rem;
                font-weight: var(--font-weight-normal);
                color: var(--color-galaxy-500-alt);
            }
        }

        .filter-table-header__filter-footer {
            padding: 0.8rem 0;
        }
    }

    .filter-table-header__filter-clear {
        color: var(--color-meteor);

        &:hover {
            color: var(--color-neptune);
        }
    }

    .filter-table-header__filter-apply {
        font-weight: var(--font-weight-normal);
    }
    .sortable-header {
        display: flex;
        align-items: center;
    }
}
