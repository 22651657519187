.assigntasksmodal-content {
  padding: 2.4rem;
  height: 100%;
  min-height: 37rem;
}

.assigntasksmodal-search {
  font-weight: 400;
  flex: 1;
}

.assigntasksmodal-footer {
  align-self: flex-end;
}
