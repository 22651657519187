.parkingpin {
    height: 2rem;
    width: 2rem;
    border-radius: var(--border-radius-round);
    background-color: var(--color-neptune);
    border: 0.1rem solid var(--color-comet);
    color: var(--color-comet);
    position: relative;
    bottom: 1rem;
    right: 1rem;
}
