.live-dispatch-table-toolbar {
    padding: 0.6rem 1.4rem;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.live-dispatch-table-toolbar__add-task-button {
    padding: 0.6rem 1.6rem;
    height: 4rem;
}

.live-dispatch-table-toolbar__header-container {
    gap: 3.2rem;
    height: 3.6rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.live-dispatch-table-toolbar__buttons-container {
    gap: 1.6rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--color-meteor);
}
