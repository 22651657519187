$z-indexes: (
    'toast': 600,
    'modal': 500,
    'navbar': 200,
    'process-indicator': 120,
    'tooltip': 110,
    'action': 20,
    'side-bar': 21,
    'map-side-bar': 18,
    'drawers': 15,
    'loading': 10,
    'map-controls-overlay': 6,
    'map-controls': 5,
    'map-controls-underlay': 4,
    'sticky-header': 3,
    'map': 0,
    'map-loader': 1,
    'inputfield': 10,
    'inputfield-overlay': 30,
    'reports-download-item-menu': 1,
    'plan-map-zone-edit-button': 1
);

:root {
    @each $name, $value in $z-indexes {
        --z-index-#{$name}: #{$value};
    }
}
