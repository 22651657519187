.task-detail-drawer {
    position: fixed;
    right: 0;
    top: var(--dimension-bar-offset-height);
    box-shadow: 0 1.5rem 3.5rem var(--color-box-shadow);
    background-color: var(--color-neptune-100);
    z-index: var(--z-index-side-bar);
    overflow: auto;

    &.drawer {
        width: 36rem;
        height: calc(100vh - var(--dimension-bar-offset-height));
    }

    &__external-task-type {
        align-items: baseline;
    }
}

.task-detail-drawer-mappage {
    &.task-detail-drawer {
        position: absolute;
        top: 0;
    }
}

.task-detail-drawer__header {
    background-color: var(--color-comet);
    padding: 1rem 1.6rem 0rem 1.6rem;
    min-height: 0;
}

.task-detail-drawer__header--sticky {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-header);
    background-color: var(--color-comet);
}

.task-detail-drawer-status {
    display: grid;
    grid-template-columns: 2.5fr;
    column-gap: 0.5rem;
}

.task-detail-drawer__map {
    height: 13.7rem;
    border-radius: var(--border-radius-l);
    overflow: hidden;
}

.task-detail-drawer__tabs {
    & .react-tabs__tab {
        width: 17.7rem;
    }
}

.task-detail__external-links {
    display: block;
    width: 100%;
    color: blue;
    column-gap: 0.8rem;
    padding: 0rem 0rem 0.8rem;
    bottom: 0.6rem;
    cursor: default;
}

.task-detail__external-links-drawer {
    display: block;
    width: 100%;
    column-gap: 0.8rem;
    padding: 0rem 0.8rem 0.8rem;
    bottom: 0.6rem;
    cursor: default;
}

.task-detail__compartment-button {
    position: relative;
    bottom: 0.8rem;
    width: 100%;
    margin-left: 0.4rem;
    padding: 0.4rem 0.4rem;
    row-gap: 0.8rem;
    flex-wrap: wrap;
    border: 0.1rem solid transparent;
    border-radius: var(--border-radius-m);
    outline: none;
    background: none;

    &:hover {
        border: 0.1rem solid var(--color-galaxy-300);
        background: var(--color-galaxy-50);
        cursor: pointer;
    }

    &:disabled {
        border: none;
        background: none;
        cursor: default;
    }
}

.task-detail__customer-notes {
    padding-left: 1rem;
    row-gap: 0.4rem;
}

.task-detail__customer-note-entry {
    padding: 0.2rem 0.8rem;
    background: var(--color-galaxy-100);
    border: 0.1rem solid var(--color-galaxy-90);
    border-radius: var(--border-radius-m);
    width: fit-content;
    font-weight: 500;
    font-size: var(--text-size-xs);
    color: var(--color-galaxy-800);
}
.task-detail__customer-notes-display {
    color: var(--color-meteor);
    font-weight: 400;
    font-size: var(--text-size-s);
    line-height: 2rem;
    cursor: pointer;
}
