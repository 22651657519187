.equipment-table-footer__content {
    display: flex;

    span + span {
        &::before {
            display: inline-block;
            content: '•';
            padding: 0 0.4rem;
        }
    }
}
