.radio-group .radio-group__label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/** @TODO Unify this style with .label-input_asterisk */
.radio-group__asterisk {
    color: var(--color-ocean);
    font-size: var(--text-size-s);
    font-weight: 500;
}
