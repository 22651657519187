.task-labels-edit {
    bottom: 0.7rem;

    &__content {
        gap: 0.8rem;
        background: var(--color-galaxy-100);
        margin-bottom: 0.7rem;
        border-radius: var(--border-radius-l);
        padding: 0.6rem 0.8rem;
    }

    &__label-input {
        padding: 0.2rem 0.4rem 0.2rem 0.8rem;
        gap: 0.4rem;
        border-radius: var(--border-radius-m);
        background: var(--color-meteor-90);
        color: var(--color-galaxy-800);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);

        input {
            border: none;
            padding: 0;
            border-radius: unset;
            height: fit-content;
            width: 8.2rem;
            bottom: unset;
            background: var(--color-meteor-90);
        }
    }
}
