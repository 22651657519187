.addressform {
    font-size: var(--text-size-m);
    color: var(--color-galaxy-800);
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2.1rem;
    row-gap: 1.2rem;

    & .label-input {
        min-width: 0;
    }

    & .formiktextinputfield {
        grid-auto-rows: auto;
    }
}

.addressform-input_addressLine1,
.addressform-input_addressLine2 {
    grid-column-start: 1;
    grid-column-end: 7;
}

.addressform-input_city {
    grid-column-start: span 2;
}

.addressform-input_state {
    grid-column-start: span 2;
}

.addressform-input_zipcode {
    grid-column-start: span 2;
}

.addressform-input_country {
    grid-column-start: 1;
    grid-column-end: 7;
}

.addressform-collapsible {
    grid-column-start: 1;
    grid-column-end: 7;

    .collapsible-toggle_button {
        justify-content: start;
        padding: 0 0;
        .collapsible-toggle_text {
            padding: 0 8px;
        }
    }

    .collapsible-panel {
        padding: 0 0;
        margin-top: 1.2rem;
    }
}

.addressform-coords {
    row-gap: 1.2rem;
    column-gap: 2.1rem;
}

.addressform-input_longitude {
    grid-column-start: 4;
    grid-column-end: 7;
    margin-bottom: 0.4rem;
}

.addressform-input_latitude {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 0.4rem;
}

.addressform-coords_alert {
    grid-column-start: 1;
    grid-column-end: 7;

    .alert_details {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.addressform-asterisk {
    color: var(--color-ocean);
    margin-left: 0.4rem;
}
