.live-dispatch-drawer__footer {
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.live-dispatch-drawer__expand-button {
    display: block;
    margin-left: auto;
    margin-right: 0;
    border: 0;
    background-color: transparent;
    width: 11.9rem;
    height: 3.6rem;
}

.live-dispatch-drawer__pagination {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 1rem;
    border: 0;
    background-color: transparent;
}

.live-dispatch-drawer__pagination-button {
    display: block;
    border: 0;
    background-color: transparent;
    width: 5rem;
    margin-top: 0.1rem;
}

.live-dispatch-drawer__pagination-button-center {
    display: block;
    border: 0;
    background-color: transparent;
    width: 5rem;
    margin-bottom: 0.1rem;
}

.expand-icon {
    margin-right: 0.4rem;
}

.live-dispatch-drawer__tasks-display-status-text {
    font-size: var(--text-size-s);
}

.live-dispatch-drawer__expand-button-text {
    font-size: var(--text-size-s);
    font-weight: var(--font-weight-normal);
    color: var(--color-galaxy-500);

    margin-left: 0.8rem;
}

.live-dispatch-drawer__pagination-text {
    font-size: var(--text-size-s);
    font-weight: var(--font-weight-normal);
    color: var(--color-galaxy-800);
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
}
