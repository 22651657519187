.live-dispatch-table-row__data-label {
    padding: 0.2rem 0.8rem;
    border-radius: var(--border-radius-xxxl);
    border: 0.1rem solid var(--color-galaxy-90);
    width: auto;
    max-width: 4.8rem;
    background-color: var(--color-comet);

    .live-dispatch-table__table--stickied & {
        border-color: var(--color-meteor-50);
        background-color: var(--color-meteor-50);
        color: var(--color-comet);

        & .highlight__highlight {
            color: inherit;
        }
    }
}
