.live-dispatch-drawer-header {
    padding: 0.8rem 1.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .live-dispatch-drawer-header__title {
        color: var(--color-live-dispatch-header);
        font-size: var(--text-size-m);
        font-weight: var(--font-weight-medium);
        line-height: 2.4rem;
        letter-spacing: var(--letter-spacing-xxs);
    }
}
