.live-dispatch-assign-driver-popup {
    .live-dispatch-popup-driver-container {
        margin-top: 1.6rem;

        .live-dispatch-popup-driver-container__title {
            line-height: 1.6rem;
            letter-spacing: var(--letter-spacing-l);
            display: block;

            color: var(--color-galaxy-800);
            font-size: var(--text-size-vxxs);
            font-weight: var(--font-weight-medium);
        }
    }
}
