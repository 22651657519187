.equipment-card {
    column-gap: 0.8rem;
    padding: 1.2rem 0.8rem;

    display: flex;
    flex-direction: row;

    border-radius: var(--border-radius-l);
    background: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 rgba(24, 41, 65, 0.15);

    &__summary {
        display: flex;
        flex-direction: column;

        gap: 0.4rem;

        width: 100%;
    }

    &__marker {
        background-color: var(--color-galaxy-50);
        border-radius: var(--border-radius-round);
        height: 2.4rem;
        width: 2.4rem;

        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        color: var(--color-comet);
    }

    &__summary-header {
        display: flex;
        justify-content: space-between;
    }

    &__text {
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        display: block;

        font-size: var(--text-size-xs);
        color: var(--color-galaxy-800);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 15rem;
    }

    &__text:first-child {
        font-weight: var(--font-weight-medium);
    }

    &__text:last-child {
        font-weight: var(--font-weight-normal);
    }

    &__status {
        max-width: 5.1rem;
        padding: 0.2rem 0.8rem;

        border-radius: var(--border-radius-xxxl);
        background: var(--color-galaxy-50);

        font-size: var(--text-size-xs);
        color: var(--color-galaxy-800);

        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        display: block;

        font-weight: var(--font-weight-medium);
    }

    &__attachments {
        display: flex;
        column-gap: 0.4rem;
    }

    &__attachment {
        color: var(--color-galaxy-500);

        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);

        padding: 0.2rem 0.8rem;

        border-radius: var(--border-radius-xxl);
        border: 0.2rem solid var(--color-galaxy-200);

        max-width: 6.4rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
