.live-dispatch-selected-driver-card {
    column-gap: 1.25rem;
    align-items: center;
    background-color: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 rgba(24, 41, 65, 0.15);
    padding: 1.2rem;
    margin-top: 0.4rem;
    border-radius: var(--border-radius-l);

    display: flex;

    .live-dispatch-selected-driver-card__marker {
        height: 2.4rem;
        width: 2.4rem;
        border-radius: var(--dimension-round);
        border: 0.1rem solid var(--color-comet);
    }

    .live-dispatch-selected-driver-card__text {
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-s);

        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        color: var(--color-galaxy-800);

        width: 15.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
