@import 'src/components/MapPageDrawers/LiveDispatchDrawer/variables';
@import 'src/components/MapPageDrawers/LiveDispatchDrawer/mixins';

.live-dispatch-table-row__data-checkbox {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--checkbox
    );
}

.unassigned-tasks-table-data__rowTaskType {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowTaskType
    );
}

.unassigned-tasks-table-data__rowExtTaskType {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowExtTaskType
    );
}

.unassigned-tasks-table-data__rowName {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowName
    );
}

.unassigned-tasks-table-data__rowLabels {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowLabels
    );
    @include flex-cell-data($flex-direction: row);
}

.unassigned-tasks-table-data__rowPickupStartTime {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowPickupStartTime
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowPickupEndTime {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowPickupEndTime
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowDeliveryStartTime {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowDeliveryStartTime
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowDeliveryEndTime {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowDeliveryEndTime
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowPickupCustomerName {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowPickupCustomerName
    );
}

.unassigned-tasks-table-data__rowPickupCustomerAddress {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowPickupCustomerAddress
    );
}

.unassigned-tasks-table-data__rowPickupCustomerCity {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowPickupCustomerCity
    );
}

.unassigned-tasks-table-data__rowDeliveryCustomerName {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerName
    );
}

.unassigned-tasks-table-data__rowDeliveryCustomerAddress {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerAddress
    );
}

.unassigned-tasks-table-data__rowDeliveryCustomerCity {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowDeliveryCustomerCity
    );
}

.unassigned-tasks-table-data__rowExtLinks {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowExtLinks
    );
    @include flex-cell-data($flex-direction: row);
}

.unassigned-tasks-table-data__rowNotes {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowNotes
    );
    @include flex-cell-data($flex-direction: row);
}

.unassigned-tasks-table-data__rowNotes button {
    flex: 1;
    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: var(--color-meteor);
}

.unassigned-tasks-table-data__rowTaskPriority {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowTaskPriority
    );
    @include flex-cell-data($flex-direction: row);
}

.unassigned-tasks-table-data__rowEquipmentId {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowEquipmentId
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowEquipmentPayerOfFreight {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowEquipmentPayerOfFreight
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowEquipmentReservation {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowEquipmentReservation
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowEquipmentType {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowEquipmentType
    );
    @include flex-cell-data();
}

.unassigned-tasks-table-data__rowEquipmentDropStay {
    width: live-dispatch-table-data-width(
        $live-dispatch-table-col-width--rowEquipmentDropStay
    );
    @include flex-cell-data();
}
