.task-labels-edit-label {
    border: 0.1rem solid var(--color-meteor);
    padding: 0.2rem 0.4rem 0.2rem 0.8rem;
    gap: 0.4rem;
    border-radius: var(--border-radius-m);
    background: var(--color-meteor-90);

    .icon-button {
        height: fit-content;
        width: fit-content;

        svg {
            fill: var(--color-task-label-cancel-icon);
        }
    }

    &__text {
        color: var(--color-galaxy-800);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
    }
}
